import React from "react"
import Layout from "@components/layout/layout"

const NotFound = () => (
  <div id="home" className="home">
    <Layout pageTitle="404" />
  </div>
)

export default NotFound
